<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2025-03-07 17:12:19
 * @LastEditors  : BigBigger
-->
<!--我的订单-->
<template>
  <div>
    <div class="ordersContain">
      <div class="title">
        <h3>我的订单</h3>
      </div>
      <template v-if="orderList && orderList.length > 0">
        <!-- <div class="til_list">
          <span class="name">课程名称</span>
          <span class="type">上课类型</span>
          <span class="time">上课时间</span>
          <span class="money">支付费用</span>
          <span class="reviewStatus">审核状态</span>
          <span class="orderStatus">订单状态</span>
          <span class="operate">操作</span>
        </div> -->
        <div class="orders_list">
          <ul>
            <li v-for="(item, index) of orderList" :key="index">
              <div class="order_top">
                <!-- <span class="creatTime">{{item.createTime}}</span> -->
                <span class="orderNumber">订单号：{{ item.orderCode }}</span>
                <span class="payStatus">订单{{ item.orderStatus | orderCompleteFilter }}</span>
              </div>
              <div class="order_detail">
                <p class="name">
                  <span class="groupname">{{ item.courseGroupName }}</span>
                  <!-- <span class="type">上课形式：{{item.categoryName}}{{item.classWay}}</span> -->
                  <span class="time">开课时间：{{ item.classTime }}</span>
                  <span style="color: red;" v-if="item.refundStatus === 4">退回原因：{{ item.reasonReturn }}</span>
                  <span style="color: red;line-height: 1.5;" v-if="[1,2].includes(item.refundStatus)">7 个工作日内复查核对后办理退款</span>
                </p>
                <p class="money">￥{{ item.payMoney }}</p>
                <p class="reviewStatus"><span :class="item.confirmStatus | classFilter">{{ item.confirmStatus |
                    confirmStatusFilter}}</span></p>
                <p class="orderStatus"><span :class="item.orderStatus | orderClassFilter">{{ item.orderStatus |
                    orderStatusFilter}}</span></p>
                <div class="operate">
                  <p class="cancelOrder cursor color-red" v-if='getPayBtn(item)' @click="toPay(item)">立即支付</p>
                  <RefundButton :item="item" @reload="getOrderList"></RefundButton>
                  <p class="cancelOrder cursor" @click="cancelOrder(item)"
                    v-if='item.type === 1 && item.confirmStatus === 1 && item.orderStatus === 1'>取消订单</p>
                  <p class="cursor" @click="jumpOrderDetail(item)">查看详情</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <el-pagination class="page-box" background layout="prev, pager, next" @current-change="pageChange"
          :page-size="limit" :total="total">
        </el-pagination>
      </template>
      <div class="empty" v-if="orderList.length === 0">
        <img src="@/assets/images/mine/empty.png" alt="暂无数据">
      </div>


    </div>
  </div>
</template>
<script>
import { getOrderList, cancelOrder } from '@/api/api'
import RefundButton from './refund.vue';
export default {
  name: 'orders',
  data() {
    return {
      page: 1,
      limit: 5,
      total: 0,
      orderList: [],
    }
  },
  components: {
    RefundButton
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    toPay(item) {
      this.$router.push({ path: `/course/signup?step=3&orderId=${item.id}` })
    },
    getPayBtn(item) {
      let state = false
      if (item.type === 1) {
        if (item.confirmStatus === 2) {
          state = item.orderStatus === 1 ? true : false
        } else {
          state = false
        }
      } else {
        state = item.orderStatus === 1 ? true : false
      }
      return state
    },
    pageChange(page) {
      this.page = page
      this.getOrderList();
    },
    async cancelOrder(val) {
      let params = {
        orderId: val.id
      }
      let response = await cancelOrder(params)
      if (+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      this.$message.success(response.returnMsg)
      this.page = 1;
      this.getOrderList()
    },
    jumpOrderDetail(val) {
      this.$router.push({ path: `/mine/orderDetail?id=${val.id}` })
    },
    // 获取订单
    async getOrderList() {
      const params = {
        page: this.page,
        limit: this.limit
      }
      let response = await getOrderList(params)
      if (+response.returnCode !== 10001) {
        return false
      }
      this.orderList = response.data
      this.total = response.total
    },
  },
  filters: {
    payStatusFilter(val) {
      if (val === 1) {
        return '未支付'
      } else if (val === 2) {
        return '已支付'
      }
    },
    confirmStatusFilter(val) {
      if (val === 1) {
        return '待审核'
      } else if (val === 2) {
        return '已审核'
      } else if (val === 3) {
        return '等待'
      } else if (val === 4) {
        return '拒绝'
      } else if (val === 5) {
        return '退班'
      }
    },
    orderStatusFilter(val) {
      if (val === 1) {
        return '未支付'
      } else if (val === 2) {
        return '已支付'
      } else if (val === 3) {
        return '已取消'
      }
    },
    classFilter(val) {
      switch (val) {
        case 1:  //待审核
          return 'waitreview';
        case 2:  //已审核
          return 'hasreview';
        case 3:  //等待
          return 'waitreview';
        case 4:  //拒绝
          return 'nopass';
        case 5:  //退班
          return 'returnClass';
      }
    },

    orderClassFilter(val) {
      switch (val) {
        case 1:  //未支付
          return 'nopay';
        case 2:  //已支付
          return 'ispay';
        case 3:  //已取消
          return 'hasCancel';
      }
    },

    orderCompleteFilter(val) {
      if (val === 1) {
        return '未完成'
      } else if (val === 2) {
        return '已完成'
      } else if (val === 3) {
        return '已取消'
      }
    },


  }


}
</script>
<style lang="less">
.ordersContain {
  width: 925px;
  padding: 0 30px;
  overflow: hidden;
  background: #fff;
  min-height: 375px;
  border-left: 1px solid #E8E8E8;
  float: left;

  .title h3 {
    font-size: 22px;
    color: #333333;
    text-align: left;
    font-weight: normal;
    line-height: 75px;
    height: 75px;
  }

  .til_list {
    padding: 0 30px;
    height: 40px;
    line-height: 40px;
    background: #F2F2F2;
    border: 1px solid #DBDBDB;
    color: #333333;
    font-size: 14px;
    margin-bottom: 30px;

    span {
      display: inline-block;
      float: left;
      text-align: center;
      padding: 0 3px;
      font-weight: 600;
    }

    .name {
      width: 175px;
    }

    .type {
      width: 160px;

    }

    .time {
      width: 175px;
    }

    .money {
      width: 105px;
    }

    .reviewStatus {
      width: 80px;
    }

    .orderStatus {
      width: 80px;
    }

    .operate {
      width: 84px;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      border: 1px solid #DBDBDB;
      margin-bottom: 30px;
      overflow: hidden;

      .order_top {
        padding: 0 30px;
        height: 40px;
        line-height: 40px;
        background: #F2F2F2;
        color: #333333;
        font-size: 14px;
        display: flex;
        justify-content: space-between;

        .payStatus {
          width: 100px;
          text-align: center;
        }
      }

      .order_detail {
        padding: 10px 28px;
        overflow: hidden;
        display: flex;
        align-items: center;

        p {
          display: inline-block;
          float: left;
          text-align: center;
          font-size: 14px;
          color: #333333;
          line-height: 30px;
          padding: 0 3px;

          .waitreview {
            // 待审核/等待
            color: #F7A121;
          }

          .hasreview {
            //已审核
            color: #00B262;
          }

          .nopass {
            //未通过
            color: #FF3F47;
          }

          .returnClass,
          .hasCancel {
            //退班
            color: #999999;
          }

          .nopay {
            //未支付
            color: #2E8DF4;
          }

          .ispay {
            //已支付
            color: #00B262;
          }

          .cancelOrder {
            //取消订单
            color: #FF3F47;
          }
        }

        .name {
          width: 465px;

          span {
            display: block;
            text-align: left;
            color: #999;
          }

          .groupname {
            font-size: 16px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .money {
          width: 120px;
          font-size: 16px;
          color: #FF3F47;
        }

        .reviewStatus {
          width: 90px;
        }

        .orderStatus {
          width: 90px;
        }

        .operate {
          width: 100px;
        }

        .cursor {
          width: 100%;
        }

        .cursor:hover {
          color: #FF3F47;
        }

        .color-red {
          width: 80px;
          border: 1px solid #FF3F47;
          color: #FF3F47 !important;
          margin-left: 10px;
        }
      }
    }
  }


}

.empty {
  height: 435px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 169px;
    height: 216px;
    display: block;
  }
}
</style>
