<!--
 * @Description  : 退款申请
 * @Author       : BigBigger
 * @Date         : 2025-02-23 17:00:08
 * @LastEditTime : 2025-03-11 09:37:16
 * @LastEditors  : BigBigger
-->
<template>
  <div v-if="item.refundSwitch === 2 && item.orderStatus === 2">
    <p class="cursor color-red" v-if="!item.refundStatus || item.refundStatus === 4" @click="showDialog()">
      申请退费
    </p>
    <p class="cursor text-blue" v-else-if="item.refundStatus === 1" @click="showDialog()">
      退费已提交
    </p>
    <p class="cursor text-blue" v-else-if="item.refundStatus === 2" @click="showDialog()">
      退费已受理
    </p>
    <p class="cursor text-red" v-else-if="item.refundStatus === 3" @click="showDialog()">
      退费完成
    </p>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" title="申请退费"
      width="800px">
      <div v-loading="loading">
        <el-card shadow="never">
          <div class="refund-title">报名信息</div>
          <el-row>
            <el-col :span="12">
              姓名：{{ refundInfo.realName }}
            </el-col>
            <el-col :span="12">
              电话：{{ refundInfo.phone }}
            </el-col>
            <el-col :span="12">
              课程名称：{{ refundInfo.courseGroupName }}
            </el-col>
            <el-col :span="12">
              订单编号：{{ refundInfo.orderCode }}
            </el-col>
          </el-row>
        </el-card>
        <el-card shadow="never" v-if="item.type !== 3">
          <div class="refund-title">岗位信息</div>
          <el-row>
            <el-col :span="12">
              部门名称：{{ refundInfo.departmentName }}
            </el-col>
            <el-col :span="12">
              单位名称：{{ refundInfo.employer }}
            </el-col>
            <el-col :span="12">
              职位名称：{{ refundInfo.positionName }}
            </el-col>
            <el-col :span="12">
              职位代码：{{ refundInfo.jobCode }}
            </el-col>
            <el-col :span="12">
              招考人数：{{ refundInfo.numberCandidates }}
            </el-col>
          </el-row>
        </el-card>
        <el-card shadow="never" v-if="!item.refundStatus || item.refundStatus === 4">
          <el-form @submit.native.prevent :model="model" :rules="rules" ref="form" hide-required-asterisk>
            <div class="refund-title">
              <span class="text-red">*</span>上传拟录用公示名单图片：
            </div>
            <el-form-item prop="publicityPicture">
              <v-upload v-model="model.publicityPicture" :maxSize="10485760" style="display: inline-block"></v-upload>
            </el-form-item>
            <template v-if="item.backPromiseSwitch == 2">
              <div class="refund-title">
                <span class="text-red">*</span>上传退款承诺书图片：
              </div>
              <el-form-item prop="backPromise">
                <v-upload v-model="model.backPromise" :maxSize="10485760" style="display: inline-block"></v-upload>
              </el-form-item>
            </template>
            <div class="refund-title">
              <span class="text-red">*</span>退款金额：
            </div>
            <el-form-item prop="refundMoney">
              <el-input v-model="model.refundMoney" type="number" placeholder="退款金额"></el-input>
            </el-form-item>
            <div class="refund-title">
              <span class="text-red">*</span>退款账户：<span v-if="refundType === 3" class="tips">（支付宝和银行卡二选一即可）</span>
            </div>
            <el-form-item class="mb-5">
              <el-radio-group v-model="model._type" @change="clearForm">
                <el-radio v-if="[1, 3].includes(refundType)" :label="1">支付宝</el-radio>
                <el-radio v-if="[2, 3].includes(refundType)" :label="2">银行卡</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="model._type === 2">
              <el-form-item prop="bankUsername">
                <el-input v-model="model.bankUsername" placeholder="持卡人"></el-input>
              </el-form-item>
              <el-form-item prop="bankDeposit">
                <el-input v-model="model.bankDeposit" placeholder="开户行"></el-input>
              </el-form-item>
              <el-form-item prop="refundAccount">
                <el-input v-model="model.refundAccount" placeholder="银行卡账号"></el-input>
              </el-form-item>
            </template>
            <template v-if="model._type === 1">
              <el-form-item prop="zfbUsername">
                <el-input  v-model="model.zfbUsername" placeholder="支付宝户名"></el-input>
              </el-form-item>
              <el-form-item prop="zfbAccount">
                <el-input  v-model="model.zfbAccount" placeholder="支付宝账号"></el-input>
              </el-form-item>
            </template>
            <div class="refund-title">备注：</div>
            <el-form-item prop="remark">
              <el-input v-model="model.remark" placeholder="备注（选填）"></el-input>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card shadow="never" v-else>
          <div class="refund-title">退款申请</div>
          <el-row>
            <el-col style="vertical-align: middle">
              拟录用公示名单图片：<el-image style="max-width: 100px; max-height: 100px" :src="refundInfo.publicityPicture"
                :preview-src-list="[
                  refundInfo.publicityPicture,
                ]">
              </el-image>
            </el-col>
            <el-col v-if="refundInfo.backPromise" style="vertical-align: middle">
              退款承诺书图片：<el-image style="max-width: 100px; max-height: 100px" :src="refundInfo.backPromise"
                :preview-src-list="[
                  refundInfo.backPromise,
                ]">
              </el-image>
            </el-col>
            <template v-if="refundInfo.zfbAccount">
              <el-col>
                支付宝账号：{{ refundInfo.zfbAccount }}
              </el-col>
              <el-col>
                支付宝户名：{{ refundInfo.zfbUsername }}
              </el-col>
            </template>
            <template v-if="refundInfo.refundAccount">
              <el-col>
                银行卡账号：{{ refundInfo.refundAccount }}
              </el-col>
              <el-col>
                持卡人：{{ refundInfo.bankUsername }}
              </el-col>
              <el-col>
                开户行：{{ refundInfo.bankDeposit }}
              </el-col>
            </template>
            <el-col> 备注：{{ refundInfo.remark }} </el-col>
          </el-row>
        </el-card>
        <div class="refund-bottom">
          <div class="text-red">*具体退款金额以签订合同为准</div>
          <div>
            <el-button v-if="!item.refundStatus || item.refundStatus === 4" type="primary"
              @click="submit">确定退费</el-button>
            <el-button @click="cancel">取消</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { orderRefundDetail, orderRefundSubmit, refundWay } from '@/api/api';
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      refundInfo: {},
      model: {},
      refundType: 0,
      rules: {
        publicityPicture: [
          {
            required: true,
            message: '请上传拟录用公示名单图片',
            trigger: 'blur',
          },
        ],
        backPromise: [
          {
            required: true,
            message: '请上传拟录用公示名单图片',
            trigger: 'blur',
          },
        ],
        refundMoney: [
          {
            required: true,
            message: '请输入退款金额',
            trigger: 'blur',
          },
        ],
        refundAccount: [
          {
            required: true,
            message: '请输入银行卡号',
            trigger: 'blur',
          },
        ],
        bankDeposit: [
          {
            required: true,
            message: '请输入开户行',
            trigger: 'blur',
          },
        ],
        bankUsername: [
          {
            required: true,
            message: '请输入持卡人',
            trigger: 'blur',
          },
        ],
        zfbAccount: [
          {
            required: true,
            message: '请输入支付宝账号',
            trigger: 'blur',
          },
        ],
        zfbUsername: [
          {
            required: true,
            message: '请输入支付宝户名',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
      this.getOrderRefundDetail();
    },
    clearForm() {
      this.model.refundAccount = undefined;
      this.model.bankUsername = undefined;
      this.model.bankDeposit = undefined;
      this.model.zfbAccount = undefined;
      this.model.zfbUsername = undefined;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        // 删除未选择类型的数据，视情况是否需要
        if (this.model._type === 1) {
          this.model.refundAccount = undefined;
          this.model.bankUsername = undefined;
          this.model.bankDeposit = undefined;
        }
        else if (this.model._type === 2) {
          this.model.zfbAccount = undefined;
          this.model.zfbUsername = undefined;
        }
        this.model._type = undefined;
        orderRefundSubmit(this.model)
          .then((res) => {
            if (res.returnCode !== '10001') {
              this.$message.error('提交失败');
              return;
            }
            this.$message.success('退款申请成功');
            this.dialogVisible = false;
            this.model = {};
            this.$emit('reload');
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.model = {};
    },
    async getOrderRefundDetail() {
      this.loading = true;
      const params = {
        orderId: this.item.id,
      };
      await refundWay(params).then(res => {
        this.refundType = res.data.type
      })
      await orderRefundDetail(params).then((result) => {
        this.refundInfo = result.data;
        this.model = {
          orderId: this.item.id,
          publicityPicture: result.data.publicityPicture,
          refundAccount: result.data.refundAccount,
          zfbAccount: result.data.zfbAccount,
          refundMoney: result.data.refundMoney,
          remark: result.data.remark,
          bankUsername: result.data.bankUsername,
          bankDeposit: result.data.bankDeposit,
          zfbUsername: result.data.zfbUsername,
          backPromise: result.data.backPromise,
          _type: this.refundType === 3 ? result.data.refundAccount ? 2: 1  : this.refundType
        };
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-card {
  margin-bottom: 20px;
  background-color: #f8f8f8;
}

.refund-title {
  font-weight: bold;
  padding-left: 10px;
  position: relative;
  margin-bottom: 5px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: #ff3f47;
  }

  .tips {
    font-weight: 400;
    color: #999;
  }
}

.refund-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-col {
  margin: 5px 0;
}

.text-red {
  color: red;
}

.ordersContain ul li .order_detail .text-blue {
  color: #1a66f3;
}

.mb-5 {
  margin-bottom: 5px;
}
</style>
